var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", {
      staticClass: "graph-container",
      staticStyle: { position: "relative" },
      attrs: { id: _vm.pageId },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }