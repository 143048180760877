var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        {
          on: { change: _vm.handleChange },
          model: {
            value: _vm.activeNames,
            callback: function ($$v) {
              _vm.activeNames = $$v
            },
            expression: "activeNames",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: _vm.$t("omit.dataInput"), name: "1" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { "margin-left": "40px" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("omit.dataInput"))),
                ]),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: _vm.$t("omit.dataOutput"), name: "2" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { "margin-left": "40px" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("omit.dataOutput"))),
                ]),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list2, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "数据编码", name: "4" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { "margin-left": "40px" } }, [
                  _vm._v(" 数据编码"),
                ]),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list4, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "量纲化处理", name: "5" } },
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { "margin-left": "40px" } }, [
                  _vm._v(" 量纲化处理"),
                ]),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list5, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "数值处理", name: "6" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "40px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(" 数值处理")]
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list6, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "时间处理", name: "7" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "40px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(" 时间处理")]
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list7, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "异常值处理", name: "8" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "40px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(" 异常值处理")]
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list8, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "自定义处理", name: "9" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "40px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v(" 自定义处理")]
                ),
              ]),
              _c(
                "ul",
                _vm._l(_vm.list9, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "getItem",
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        "data-shape": item.shape,
                        "data-type": item.type,
                        "data-size": item.size,
                        draggable: "",
                      },
                      on: {
                        dragstart: _vm.handleDragstart,
                        dragend: function ($event) {
                          return _vm.handleDragEnd($event, item)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }