var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      {
        ref: "contextMenu",
        staticClass: "el-scrollbar__view el-select-dropdown__list context-menu",
      },
      _vm._l(_vm.menus, function (menu) {
        return _c(
          "li",
          {
            key: menu.key,
            staticClass: "el-select-dropdown__item",
            on: {
              click: function ($event) {
                return _vm.handleClick(menu)
              },
            },
          },
          [_vm._v(_vm._s(menu.name))]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }