<template>
  <div class="page">
    <div :id="pageId" class="graph-container" style="position: relative;"></div>
    
  </div>
</template>


<script>
import G6 from "@antv/g6/build/g6";
// import { initBehavors } from "@/behavior";
import { initBehavors } from "../../behavior/index"
export default {
  data() {
    return {
      pageId: "graph-container",
      graph: null,
      data2:{ "nodes": [],
       "edges": [],
       "groups": []}
    };
  },
  props: {
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    initBehavors();
  },
  mounted() {
    
     

      // this.$nextTick(()=>{
         this.init();
      // })
      this.$eventBus.$on('eventNameq2', (res)=>{
        console.log("page页面");
     setTimeout(() => {
      if(res.data.data.g6){
        this.data2 =  JSON.parse(res.data.data.g6)  
        // this.readData()
      }else{
        this.data2 = { "nodes": [],
       "edges": [],
       "groups": []}
      }
        this.readData()
     }, 1);
      
});


   
  },
  methods: {
    init() {
      const height =  this.height - 42 
      const width =  this.width - 400
      this.graph = new G6.Graph({
        container: "graph-container",
        height: height,
        width: width,
        modes: {
          // 支持的 behavior
          default: [
            "drag-canvas",
            "zoom-canvas",
            "hover-node",
            "select-node",
            "hover-edge",
            "keyboard",
            "customer-events",
            "add-menu"
          ],
          mulitSelect: ["mulit-select"],
          addEdge: ["add-edge"],
          moveNode:[ "drag-item"]
        }
      });
      const { editor, command } = this.$parent;
      editor.emit("afterAddPage", { graph: this.graph, command });
      this.readData();
        
     

      
    },
    readData() {

      if (this.data2) {
          this.graph.read(
            this.data2
    )}
  //     this.data = {
  //       "nodes": [],
  //   "edges": [],
  //   "groups": []
  // }

  //     let data = this.data2;
   
      // if (data) {
    //     console.log(this.data2,"ffffffffffff");
    //     this.graph.read(
    //       this.data2
    // );
      // }
    }
  }
};
</script>

<style scoped>
.page{
  margin-left:200px;
  margin-right: 200px;
}
</style>