var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "navigator" } }, [
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _c("h6", [
        _vm._v("\n    " + _vm._s(_vm.$t("projectlist.btn.Navigator")) + "\n  "),
      ]),
    ]),
    _c("div", {
      ref: "minimap",
      staticClass: "minimap",
      attrs: { id: "minimap" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }